import React from 'react';

const DomesticSEOContainer = () => {
	return (
		<div className="seo_domestic">
			<div className="seo-content">
				<h3>携程旅行网提供机票预订，飞机票查询时刻表。票价查询以及特价机票，便宜机票，打折机票查询预订服务</h3>
				<p>
					<strong>热门航线</strong>
					<a
						href="//flights.ctrip.com/booking/TYN-BJS-day-1.html"
						title="太原到北京机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						太原到北京机票
					</a>
					<a
						href="//flights.ctrip.com/booking/SHA-KWL-day-1.html"
						title="上海到桂林机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						上海到桂林机票
					</a>
					<a
						href="//flights.ctrip.com/booking/HGH-SIA-day-1.html"
						title="杭州到西安机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						杭州到西安机票
					</a>
					<a
						href="//flights.ctrip.com/booking/HGH-CAN-day-1.html"
						title="杭州到广州机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						杭州到广州机票
					</a>
					<a
						href="//flights.ctrip.com/booking/WUH-SZX-day-1.html"
						title="武汉到深圳机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						武汉到深圳机票
					</a>
					<a
						href="//flights.ctrip.com/booking/SIA-SZX-day-1.html"
						title="西安到深圳机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						西安到深圳机票
					</a>
					<a
						href="//flights.ctrip.com/booking/SZX-SHA-day-1.html"
						title="深圳到上海机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						深圳到上海机票
					</a>
					<a
						href="//flights.ctrip.com/booking/KMG-SHA-day-1.html"
						title="昆明到上海机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						昆明到上海机票
					</a>
					<a
						href="//flights.ctrip.com/booking/SHA-DLC-day-1.html"
						title="上海到大连机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						上海到大连机票
					</a>
					<a
						href="//flights.ctrip.com/booking/SHA-TYN-day-1.html"
						title="上海到太原机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						上海到太原机票
					</a>
					<a
						href="//flights.ctrip.com/booking/BAV-BJS-day-1.html"
						title="包头到北京机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						包头到北京机票
					</a>
					<a
						href="//flights.ctrip.com/booking/SIA-CAN-day-1.html"
						title="西安到广州机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						西安到广州机票
					</a>
					<a
						href="//flights.ctrip.com/booking/FOC-CAN-day-1.html"
						title="福州到广州机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						福州到广州机票
					</a>
					<a
						href="//flights.ctrip.com/booking/CAN-XMN-day-1.html"
						title="广州到厦门机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						广州到厦门机票
					</a>
					<a
						href="//flights.ctrip.com/booking/BJS-CGO-day-1.html"
						title="北京到郑州机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						北京到郑州机票
					</a>
					<a
						href="//flights.ctrip.com/booking/HRB-SHA-day-1.html"
						title="哈尔滨到上海机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						哈尔滨到上海机票
					</a>
					<a
						href="//flights.ctrip.com/booking/NKG-BJS-day-1.html"
						title="南京到北京机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						南京到北京机票
					</a>
					<a
						href="//flights.ctrip.com/booking/KWE-BJS-day-1.html"
						title="贵阳到北京机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						贵阳到北京机票
					</a>
					<a
						href="//flights.ctrip.com/booking/BJS-SHA-day-1.html"
						title="北京到上海机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						北京到上海机票
					</a>
					<a
						href="//flights.ctrip.com/booking/KHN-CAN-day-1.html"
						title="南昌到广州机票"
						target="_blank"
						rel="noopener noreferrer"
					>
						南昌到广州机票
					</a>
				</p>
				{/* SEO:SEO区域块 */}
				<p>
					<strong>机票工具箱</strong>
					<a href="http://flights.ctrip.com/booking/china-city-flights-sitemap.html" title="打折机票">
						打折机票
					</a>{' '}
					<a href="http://flights.ctrip.com/schedule/" title="航班查询时刻表">
						航班时刻表查询
					</a>{' '}
					<a href="http://flights.ctrip.com/actualtime/" title="航班实时起降查询">
						航班动态查询
					</a>{' '}
					<a href="http://flights.ctrip.com/booking/hot-city-flights-sitemap.html" title="机票地图">
						机票地图
					</a>{' '}
					<a href="http://flights.ctrip.com/domestic/checkinseat/index" title="值机">
						值机
					</a>
				</p>
				<p>
					<strong>打折机票</strong>
					<a href="http://flights.ctrip.com/booking/sha-Shanghai-flights.html">上海特价机票</a>
					<a href="http://flights.ctrip.com/booking/bjs-Beijing-flights.html">北京特价机票</a>
					<a href="http://flights.ctrip.com/booking/can-Guangzhou-flights.html">广州特价机票</a>
					<a href="http://flights.ctrip.com/booking/szx-Shenzhen-flights.html">深圳特价机票</a>
					<a href="http://flights.ctrip.com/booking/ckg-Chongqing-flights.html">重庆特价机票</a>
					<a href="http://flights.ctrip.com/booking/ctu-Chengdu-flights.html">成都特价机票</a>
					<a href="http://flights.ctrip.com/booking/kmg-Kunming-flights.html">昆明特价机票</a>
					<a href="http://flights.ctrip.com/booking/xmn-Xiamen-flights.html">厦门特价机票</a>
					<a href="http://flights.ctrip.com/booking/syx-Sanya-flights.html"> 三亚特价机票</a>
					<a href="http://flights.ctrip.com/booking/dlc-Dalian-flights.html"> 大连特价机票</a>
					<a href="http://flights.ctrip.com/booking/ljg-Lijiang-flights.html">丽江特价机票</a>
					<a href="http://flights.ctrip.com/booking/tsn-Tianjin-flights.html">天津特价机票</a>
					<a href="http://flights.ctrip.com/booking/foc-Fuzhou-flights.html">福州特价机票</a>
					<a href="http://flights.ctrip.com/booking/she-Shenyang-flights.html">沈阳特价机票</a>
					<a href="http://flights.ctrip.com/booking/cgo-Zhengzhou-flights.html"> 郑州特价机票</a>
					<a href="http://flights.ctrip.com/booking/tao-Qingdao-flights.html">青岛特价机票</a>
					<a href="http://flights.ctrip.com/booking/sia-Xian-flights.html">西安特价机票</a>
					<a href="http://flights.ctrip.com/booking/nkg-Nanjing-flights.html">南京特价机票</a>
					<a href="http://flights.ctrip.com/booking/wuh-Wuhan-flights.html">武汉特价机票</a>
					<a href="http://flights.ctrip.com/booking/hgh-Hangzhou-flights.html">杭州特价机票</a>
					<a href="http://flights.ctrip.com/booking/china-city-flights-sitemap.html">更多特价机票</a>
				</p>
				<p>
					<strong>航空机票</strong>
					<a href="http://9cair.flights.ctrip.com/">春秋航空</a>
					<a href="http://muair.flights.ctrip.com/">东方航空</a>
					<a href="http://czair.flights.ctrip.com/">南方航空</a>
					<a href="http://caair.flights.ctrip.com/">中国国航</a>
					<a href="http://huair.flights.ctrip.com/">海南航空</a>
					<a href="http://zhair.flights.ctrip.com/">深圳航空</a>
					<a href="http://mfair.flights.ctrip.com/"> 厦门航空</a>
					<a href="http://scair.flights.ctrip.com/">山东航空</a>
					<a href="http://hoair.flights.ctrip.com/">吉祥航空</a>
					<a href="http://jdair.flights.ctrip.com/">首都航空</a>
					<a href="http://3uair.flights.ctrip.com/">四川航空</a>
					<a href="http://jrair.flights.ctrip.com/">幸福航空</a>
					<a href="http://8lair.flights.ctrip.com/">祥鹏航空</a>
					<a href="http://fmair.flights.ctrip.com/">上海航空</a>
				</p>
			</div>
		</div>
	);
};

export default DomesticSEOContainer;
